/* styles.css */

@media (max-width:1000px) {
  .lobby-username {
    width: 50% !important;
    font-weight: normal !important;
  }

  .card {
    width: 90% !important;
    background-color: rgb(255, 255, 255);
    border: 1px solid black;
    border-radius: 10px;
    position: relative; 
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    /* justify-content: center; */
    align-items: center;
  }
  
  .genre-grid {
    display: grid;
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
    grid-gap: 5px !important; /* Adjust the gap between grid items as needed */
    text-align: center;
  }
  
  .genre-item {
    /* margin-top: 5px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust the item height as needed */
    background-color: #f2f2f2; /* Adjust the background color as needed */
    cursor: pointer;
  }
  
  .genre-item.selected {
    background-color: #6d68ff; /* Adjust the selected item color as needed */
  }  
  
  .card-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .card-header {
    color: #ff8c00; /* complementary color */
    text-shadow: 2px 2px 2px #000; /* add a text shadow */
    stroke: black; /* add a stroke */
    text-align: center;
    border-radius: 5px 5px 0 0;
    font-size: 36px;
    font-weight: normal !important;
    -webkit-text-stroke: 1px black;
    text-stroke: 1px black;
  }

  .form-label {
    font-weight: normal !important;
    text-align: center;
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    font-weight: normal !important;
  }
  
  .lobby-column {
    text-align: center;
    border-radius: 5px 5px 0 0;
    width: 100% !important;
    float: left;
    box-sizing: border-box;
    padding: 8px;
  }
  .login-header {
    color: #ffe14f; /* complementary color */stroke: black; /* add a stroke */
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
  }
  .stats-header {
    color: #BA55D3;
    stroke: black; /* add a stroke */
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
  }

  .stats-stroke {
    -webkit-text-stroke: 1px black;
    text-stroke: 1px black;
  }

  .lobby-username {
    display: inline-block;
    color: #6d68ff; /* complementary color */
    text-shadow: 2px 2px 2px #000; /* add a text shadow */
    stroke: black; /* add a stroke */
    text-align: center;
    font-size: 36px;
    font-weight: normal !important;
    -webkit-text-stroke: 1px black;
    text-stroke: 1px black;
  }
  
  .status-text {
    color: #6d68ff; 
    text-shadow: 1px 1px 1px #000;
    stroke: black;
    text-align: center;
    border-radius: 5px 5px 0 0;
    font-size: 22px;
    font-weight: normal !important;
    -webkit-text-stroke: 1px black;
    text-stroke: 1px black;
  }

  .button {
    height: 50px;
    background-color: #8A2BE2;
    text-decoration: none;
    color: white;
    width: auto !important;
    padding: 10px;
    margin-top: 20px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .ready-button {
    background-color: #50c878;
    text-decoration: none;
    color: #ffe14f;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

body {
  background-color: #ededed;
}

.genre-grid {
  display: grid;
  max-width: 90%;
  margin-top: 10px;
  margin-bottom: 10px;
  grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns as needed */
  grid-gap: 5px !important; /* Adjust the gap between grid items as needed */
  text-align: center;
}

.ready {
  color: rgb(255, 0, 0);
}

.idle {
  color: #50c878;
}

.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.google-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1%;
}

.button-container {
  color:#C6E2D5;
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.button {
  background-color: #8A2BE2;
  height: auto;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 28px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.question-button {
  background-color: #8A2BE2;
  width: auto;
  height: auto;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 28px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.return-button {
  background-color: #4642c3;
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: auto;
  text-decoration: none;
  color: white;
  padding: 5px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.selected {
  margin: 5px;
  background-color: #EB52D6;
  height: auto;
  width: auto;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 24px;
  width: 100%;
  height: 100%;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.unselected {
  margin: 3px;
  background-color: #8A2BE2;
  text-align: center;
  height: auto;
  width: auto;
  color: white;
  width: 100%;
  height: 100%;
  padding: 5px 5px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.category-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selected-category-container {
  display: grid;
  place-items: center;
}

.back-button {
  margin: 3px;
  text-align: center;
  background-color: #A352EB;
  height: auto;
  width: auto;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form {
  height: 30px;
  width: 50%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.invite-button {
  height: 30px;
  margin-left: 10px;
  margin-top: 5px;
  background-color: #8A2BE2;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submission-message{
  color: green;
}

.user-info span p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
}

.menu {
  display: flex;
  justify-content: flex-start;
  position: relative;
  text-decoration: none;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
}

.menu-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #8A2BE2;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.menu-header {
  color: #8A2BE2; /* complementary color */
    stroke: black; /* add a stroke */
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
}

.menu-username {
  display: inline-block;
  color: #6d68ff; /* complementary color */
  text-shadow: 2px 2px 2px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  padding-left: 10px;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.username {
  color: #8A2BE2; /* complementary color */
  stroke: black; /* add a stroke */
  margin-top: 5px;
  padding-left: 10px;
  text-align: center;
  font-size: 28px;
}

.menu-li-button {
  display: block;
  margin: 0 10px 10px 0; /* Adjust the spacing as needed */
  margin-bottom: 10px;
  background-color: #8A2BE2;
  text-decoration: none;
  text-align: center;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



.ready-button {
  background-color: #6A5ACD;
  text-decoration: none;
  margin: 10px;
  padding: 10px;
  color: #FFFFFF;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.button-link {
  text-decoration: none;
  color: white;
}

.error-message {
  color: red;
}

.form-label {
  font-weight: bold;
  text-align: center;
}

.label-text {
  margin-bottom: 5px;
}

.form-input {
  display: block;
  margin: 0 auto;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
}

input {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 5px;
}

.card {
  max-width: 75%;
  height: auto;
  background-color: #C6E2D5;
  border: 1px solid black;
  border-radius: 10px;
  position: relative; 
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  align-items: center;
}

.card-header {
  color: #BA55D3; /* complementary color */
  text-shadow: 2px 2px 2px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-size: 34px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.card-page {
  background-color: #ededed; /* set the background color to a light gray */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.card-question {
  color: #BA55D3; /* complementary color */
  text-shadow: 2px 2px 2px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  margin: 20px 20px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-size: 34px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.card-username {
  color: #ff0000; /* complementary color */
  text-shadow: 2px 2px 2px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-size: 34px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-right: 5px; 
  border-radius: 5px;
}

.user-icon {
  margin-top: 5px;
  width: 40px; /* Adjust the size as needed */
  height: 40px;
}

.user-name {
  display: inline-block;
  color: #8A2BE2; /* complementary color */
  text-shadow: 2px 2px 2px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  padding: 10px 10px;
  font-size: 36px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
  vertical-align: middle; /* align the user name vertically */
}

.menu-icon {
  margin-left: 10px;
  margin-top: 10px;
  width: 30px; /* Adjust the size as needed */
  height: 30px;
}


.login-header {
  color: #BA55D3; /* complementary color */
  text-shadow: 2px 2px 2px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  padding: 10px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-family: 'Roboto', sans-serif;
  font-size: 36px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}



.stats-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stats-header {
  color: #BA55D3;
  text-align: center;
  /* border-radius: 5px 5px 0 0; */
  font-size: 36px;
  font-weight: bold;
  -webkit-text-stroke: 1px #000;
}

.stats-row {
  color: #006666; 
  max-width: 80%;
  height: auto;
  stroke: black; /* add a stroke */
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
}

.stats-text { 
  text-align: center;
}

.stats-question {
  height: auto;
  background-color: #BA55D3;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
  outline: 1px solid black;
}

.stats-guess {
  height: auto;
  background-color: #FF69B4;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
  outline: 1px solid black;
}

.stats-answer-correct {
  height: auto;
  background-color: #A3DEAE;
  text-decoration: none;
  color: #124721;
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
  border: none;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: 1px solid black;
}

.stats-answer-incorrect {
  height: auto;
  background-color: #9B1C47;
  text-decoration: none;
  color: white;
  padding: 10px;
  font-size: 18px;
  border: none;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: 1px solid black;
}

.status-text {
  color: #6d68ff; 
  text-shadow: 1px 1px 1px #000;
  stroke: black;
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-size: 22px;
  font-weight: bold;
  /* -webkit-text-stroke: 1px black;
  text-stroke: 1px black; */
}

.lobby-waiting {
  color: #ff0000; /* complementary color */
  stroke: black; /* add a stroke */
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
}

.lobby-header {
  color: #BA55D3; /* complementary color */
  width: auto;
  /* background-color: #F2F2F2; */
  padding: 5px 10px;
  border-radius: 5px;
  stroke: black; /* add a stroke */
  text-align: center;
  justify-content: center;
  justify-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
}

.lobby-userlist {
  color: #000000; /* complementary color */
  width: auto;
  /* background-color: #F2F2F2; */
  padding: 5px;
  border-radius: 5px;
  stroke: black; /* add a stroke */
  text-align: center;
  align-content: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
}

.lobby-row {
  color: #BA55D3; /* complementary color */
  stroke: black; /* add a stroke */
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
}

.lobby-genre {
  display: inline-block;
  color: #744FE0; /* complementary color */
  text-shadow: 1px 1px 1px #000; /* add a text shadow */
  stroke: black; /* add a stroke */
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.lobby-column {
  text-align: center;
  border-radius: 5px 5px 0 0;
  width: auto;
  float: left;
  box-sizing: border-box;
}

.user-status {
  color: rgb(60, 60, 60);
}

.lobby-container {  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lobby-stroke {
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}

.start-button {
  background-color: #6d68ff;
  height: auto;
  text-decoration: none;
  color: white;
  margin: 5px;
  padding: 10px;
  font-size: 28px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
}

.genre-column {
  text-align: center;
  border-radius: 5px 5px 0 0;
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 8px;
}


.genre-header {
  color: #BA55D3; /* complementary color */
  width: auto;
  /* background-color: #F2F2F2; */
  padding: 5px 10px;
  border-radius: 5px;
  stroke: black; /* add a stroke */
  text-align: center;
  justify-content: center;
  justify-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
}

ul {
  margin: 0 auto;
  width: 50%; /* adjust the width as needed */
  padding-left: 0;
}